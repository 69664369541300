// import React from 'react';
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import SpiritualLife from "./pages/Spiritual-Life";
import WorkLife from "./pages/Work-Life";
import EducationLife from "./pages/Education-Life";
import PersonalLife from "./pages/Personal-Life";

export default function App() {
  return (
    <Routes>
      <Route path="" element={<Home />} />
      <Route path="spiritual-life" element={<SpiritualLife />} />
      <Route path="work-life" element={<WorkLife />} />
      <Route path="education-life" element={<EducationLife />} />
      <Route path="personal-life" element={<PersonalLife />} />
    </Routes>
  );
}
