// import React from "react";
import { SetStateAction, useState } from "react";
import "./../App.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   // solid,
// //   regular,
//   //   brands,
// } from "@fortawesome/fontawesome-svg-core/import.macro";

declare global {
  interface Window {
    emailjs: any;
  }
}

// let emailjs = window.emailjs; // ok now

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [nameErr, setNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [messageErr, setMessageErr] = useState(false);

  function handleNameInput(event: {
    target: { value: SetStateAction<string> };
  }) {
    setName(event.target.value);
  }

  function handleEmailInput(event: {
    target: { value: SetStateAction<string> };
  }) {
    setEmail(event.target.value);
  }

  function handleMessageInput(event: {
    target: { value: SetStateAction<string> };
  }) {
    setMessage(event.target.value);
  }

  function submitForm() {
    if (name === "") {
      setNameErr(true);
    } else {
      setNameErr(false);
    }
    if (email === "") {
      setEmailErr(true);
    } else {
      setEmailErr(false);
    }
    if (message === "") {
      setMessageErr(true);
    } else {
      setMessageErr(false);
    }

    if (name === "" || email === "" || message === "") {
      return;
    }

    window.emailjs.send("service_fbvvvjc", "template_e4lxzrb", {
      from_name: name,
      email_address: email,
      message: message,
    });
    setName("");
    setEmail("");
    setMessage("");
  }

  return (
    <div
      id="contact"
      className="contact pt-40 font-bold h-8.5hpx bg-gradient-to-b from-about-background to-footer-background p-20"
    >
      <h2 className="text-3xl text-center font-bold text-white">Contact Me</h2>
      <div className="m-20 flex items-center flex row smw:m-2 smw:flex-col">
        <div className="flex items-center flex-col w-full mx-20 smw:mx-2">
          <div className="NameSection w-full mb-10 smw:mb-3">
            <label className="text-white font-bold m-1">Name</label>
            <input
              className="placeholder:italic placeholder:text-slate-400
              w-full block bg-white border border-slate-300 rounded-md
              py-2 pl-4 pr-3 shadow-sm focus:outline-none sm:text-sm my-2
              focus:bg-home-page"
              placeholder="Enter your name here..."
              type="text"
              name="search"
              value={name}
              onChange={handleNameInput}
            />
            {nameErr && <p className="text-red-500">You must specify a name</p>}
          </div>

          <div className="NameSection w-full">
            <label className="text-white font-bold m-1">Email</label>
            <input
              className="placeholder:italic placeholder:text-slate-400
              w-full block bg-white border border-slate-300 rounded-md
              py-2 pl-4 pr-3 shadow-sm focus:outline-none sm:text-sm my-2
              focus:bg-home-page"
              placeholder="Enter your email here.."
              type="text"
              name="search"
              value={email}
              onChange={handleEmailInput}
            />
            {emailErr && (
              <p className="text-red-500">You must specify an email</p>
            )}
          </div>
        </div>
        <div className="NameSection w-full mx-20 smw:my-4">
          <label className="text-white font-bold m-1">Message</label>
          <textarea
            className="placeholder:italic placeholder:text-slate-400
            w-full block bg-white border border-slate-300 rounded-md
            py-2 pl-3 pr-3 shadow-sm focus:outline-none sm:text-sm my-2
            h-40 focus:bg-home-page"
            placeholder="Type your message here..."
            name="search"
            value={message}
            onChange={handleMessageInput}
          />
          {messageErr && (
            <p className="text-red-500">You must include a message</p>
          )}
        </div>
      </div>
      <div className="flex flex-col items-center">
        <input
          className="SubmitButton bg-white text-2xl py-4 px-16 rounded-md border border-slate-300
          active:bg-green-400 active:border-green-400 hover:bg-home-page hover:cursor-pointer"
          type="submit"
          value="Submit"
          onClick={submitForm}
        />
      </div>
    </div>
  );
}
