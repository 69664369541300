import "./../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  // regular,
  //   brands,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  Link,
  // Element,
  // Events,
  // animateScroll as scroll,
  // scrollSpy,
  // scroller,
} from "react-scroll";

export default function Meet() {
  return (
    <div id="Meet" className="screen h-screen smh:h-smhpx">
      <div className="Meet flex pt-20 flex flex-col items-center">
        <h1 className="text-xl m-8">Welcome! I'm Caleb Sipper.</h1>
        <div>
          <img
            className="border rounded-full h-60 w-60 drop-shadow-lg"
            src={process.env.PUBLIC_URL + "/images/Profile_image.jpg"}
            alt="me"
          />
        </div>
        <Link
          activeClass="active"
          className="absolute bottom-10 smh:hidden hover:cursor-pointer"
          to="About"
          spy={true}
          smooth={true}
          duration={500}
        >
          <FontAwesomeIcon
            className="w-10 h-10 hover:opacity-80"
            icon={solid("chevron-down")}
          />
        </Link>
      </div>
    </div>
  );
}
