// import React from "react";
import "./../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  // regular,
  // brands,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import "tw-elements";
import { Link } from "react-router-dom";

export default function Navbar() {
  const links = [
    {
      title: "Spiritual Life",
      route: "spiritual-life",
    },
    {
      title: "Work Life",
      route: "work-life",
    },
    {
      title: "Education Life",
      route: "education-life",
    },
    {
      title: "Personal Life",
      route: "personal-life",
    },
  ];
  return (
    <div>
      <div
        id="Navbar"
        className="Navbar w-full bg-navbar-background h-32 flex drop-shadow-lg p-2 place-content-between fixed z-10"
      >
        <div className="eagle-image w-28 h-28 ml-12">
          <img src={process.env.PUBLIC_URL + "/images/Eagle.png"} alt="me" />
        </div>
        <div className="m-10 flex space-x-20 pr-4">
          <a className="" href="/" title="Home">
            <FontAwesomeIcon
              className="w-10 h-10 hover:opacity-80"
              icon={solid("house")}
            />
          </a>

          <div className="flex justify-center">
            <div>
              <div className="dropdown relative">
                <FontAwesomeIcon
                  className="w-10 h-10 hover:cursor-pointer dropdown-toggle hover:opacity-80"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  icon={solid("align-justify")}
                />
                <ul
                  className="dropdown-menu min-w-max w-40 hidden bg-white text-base z-50 float-left py-2 list-none
                    text-left rounded-lg shadow-lg mt-1 hidden m-0 bg-clip-padding border-none"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {links.map((link) => {
                    return (
                      <li>
                        <Link
                          className="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                          to={"/" + link.route}
                        >
                          {link.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="behind-navbar h-32"></div>
    </div>
  );
}

/*

*/
