// import React from 'react';
import "./../App.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function WorkLife() {
  return (
    <div className="App min-w-screen min-h-screen bg-home-page ">
      <Navbar />
      <p className="my-60 text-xl text-center">
        Whoops! Looks like I haven't been filled out yet. Check back later.
      </p>
      <div className="absolute bottom-0 w-screen">
        <Footer />
      </div>
    </div>
  );
}
