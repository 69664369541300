// import React from 'react';
import "./../App.css";
import Navbar from "./../components/Navbar";
import Meet from "./../components/Meet";
import About from "./../components/About";
import Contact from "./../components/Contact";
import Footer from "./../components/Footer";

export default function Home() {
  return (
    <div className="App min-w-screen min-h-screen bg-home-page">
      <Navbar />
      <Meet />
      <About />
      <Contact />
      <div className="bottom-0 w-full">
        <Footer />
      </div>
    </div>
  );
}
