// import React from "react";
import "./../App.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   solid,
//   //   regular,
//   //   brands,
// } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  // Link,
  // DirectLink,
  Element,
  // Events,
  // animateScroll as scroll,
  // scrollSpy,
  // scroller,
} from "react-scroll";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <Element name="About" className="element">
      <div
        id="About"
        className="About gap-10 flex flex-wrap place-content-evenly bg-gradient-to-b from-home-page to-about-background h-8.5hpx p-x-10 pt-36
        smw:gap-5 smw:flex-nowrap smw:flex-col smw:h-1.1kpx"
      >
        <Link
          className="spiritual-life card rounded-lg border-black bg-white drop-shadow-md p-4
          smw:p-2 flex w-5/12 smw:w-11/12 smw:m-4 hover:opacity-80"
          to="/spiritual-life"
        >
          <img
            className="w-60 h-60 p-2 border rounded
            smw:w-40 smw:h-40 smw:p-1"
            src={process.env.PUBLIC_URL + "/images/spiritual-life.jpg"}
            alt="Spiritual Life"
          />
          <div className="information w-full text-center p-2 smw:p-0">
            <h2 className="text-2xl smw:text-md smw:pt-1">Spiritual Life</h2>
            <p className="p-2 smw:text-xs">
              Caleb is a new testament christian and a servant of God. He
              believes the Bible is the book of truth and is inspired by God
              {" ("}
              <a
                className="hover:text-blue-600 active:text-blue-900"
                href="https://www.blueletterbible.org/nkjv/2ti/3/16/s_1128016"
              >
                2 Timothy 3:16
              </a>
              {")"}.
            </p>
            <p className="pt-3 xsw:hidden">
              Click here for more information about what I believe?{" "}
            </p>
          </div>
        </Link>
        <Link
          className="work-life card rounded-lg border-black bg-white drop-shadow-md p-4
          smw:p-2 flex w-5/12 smw:w-11/12 smw:m-4 hover:opacity-80"
          to="/work-life"
        >
          <img
            className="w-60 h-60 p-2 border rounded
            smw:w-40 smw:h-40 smw:p-1"
            src={process.env.PUBLIC_URL + "/images/work-life.jpg"}
            alt="Spiritual Life"
          />
          <div className="information w-full text-center p-2smw:p-0">
            <h2 className="text-2xl smw:text-md smw:pt-1">Work Life</h2>
            <p className="p-2 smw:text-xs">
              Caleb is a dedicated software developer with a passion for coding.
              "Seeing the product finally work the way it should brings me so
              much joy!" - Caleb
            </p>
            <p className="pt-3 xsw:hidden">
              Click here for more information about my skill set and work
              experience?{" "}
            </p>
          </div>
        </Link>
        <Link
          className="education-life card rounded-lg border-black bg-white drop-shadow-md p-4
          smw:p-2 flex w-5/12 smw:w-11/12 smw:m-4 hover:opacity-80"
          to="/education-life"
        >
          <img
            className="w-60 h-60 p-2 border rounded
            smw:w-40 smw:h-40 smw:p-1"
            src={process.env.PUBLIC_URL + "/images/education-life.jpg"}
            alt="Education Life"
          />
          <div className="information w-full text-center p-2smw:p-0">
            <h2 className="text-2xl smw:text-md smw:pt-1">Education Life</h2>
            <p className="p-2 smw:text-xs">
              Caleb is current student at Freed-Hardeman University in
              Henderson, Tennessee. He plans to graduate in December of 2022
              with a bachelor in computer science with an emphasis in software
              development.
            </p>
            <p className="pt-3 xsw:hidden">
              Click here for more information about my education?{" "}
            </p>
          </div>
        </Link>
        <Link
          className="personal-life card rounded-lg border-black bg-white drop-shadow-md p-4
          smw:p-2 flex w-5/12 smw:w-11/12 smw:m-4 hover:opacity-80"
          to="/personal-life"
        >
          <img
            className="w-60 h-60 p-2 border rounded
            smw:w-40 smw:h-40 smw:p-1"
            src={process.env.PUBLIC_URL + "/images/personal-life.jpg"}
            alt="Spiritual Life"
          />
          <div className="information w-full text-center p-2smw:p-0">
            <h2 className="text-2xl smw:text-md smw:pt-1">Personal Life</h2>
            <p className="p-2 smw:text-xs">
              Caleb loves to play ping pong, disc golf, tennis, and other
              outdoor games. He also enjoys board games and select video games.
              He is enthusiastic about running {"D&D"} campaigns and designing
              trading card games.
            </p>
            <p className="pt-3 xsw:hidden">
              Click here for more information about my personal life and
              hobbies?{" "}
            </p>
          </div>
        </Link>
      </div>
    </Element>
  );
}
