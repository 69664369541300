// import React from "react";
import "./../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // solid,
  regular,
  //   brands,
} from "@fortawesome/fontawesome-svg-core/import.macro";

export default function Footer() {
  return (
    <div
      id="Footer"
      className="footer bg-footer-background h-30 w-full text-center text-white"
    >
      <div className="final-remarks py-8 text-l">
        Copyright
        <FontAwesomeIcon
          className="h-2.5 w-2.5 pb-2"
          icon={regular("copyright")}
        />{" "}
        2022 All rights reserved
      </div>
    </div>
  );
}
